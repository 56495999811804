<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>View Member Roles</h1>
    </header>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const name = ref('view-roles')
    return { name }
  }
}
</script>

<style lang="scss">
